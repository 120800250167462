@use '/src/styles/partials/_colors.scss' as *;
@use '/src/styles/partials/_variables.scss' as *;
@use '/src/styles/partials/_typography.scss' as *;

.wrapper {
  display: flex;
  flex: 1 1;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;

  * {
    text-align: center;
  }

  h1 {
    @include typeface-logo(desktop);
    color: $purple;
    background: linear-gradient(225deg, $purple 0%, $purple-dark 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h5 {
    @include typeface(h4, desktop);
    margin-bottom: 0.5rem;
  }

  p {
    @include typeface(h6, desktop);
    font-weight: 300;
    color: $gray-6;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    height: 10rem;
    width: 10rem;
    position: relative;
  }
}
